import React from 'react';
import {
  makeStyles,
  Typography,
  Container,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { navigate } from "gatsby"
import PlusIcon from '../../assets/icons/plus.inline.svg';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4, 0),
  },
  card: {
    padding: theme.spacing(6),
    backgroundColor: '#F7F7F7',
    borderRadius: 23,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(10, 6),
      textAlign: 'center',
    },
  },

  button: {
    minWidth: 220,
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  buttonWrapper: {
    display: 'flex',
  },
  text: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 0, 6),
    },
  },

  faqTextContainer: {
    padding: theme.spacing('100px', 0),
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(8, 0),
    },
  },

  faqContainer: {
    '& > *:first-child': {
      borderTop: '2px solid #E2E2E2',
    },
  },
}));

export default function SectionB() {
  const classes = useStyles();

  const handleClickContact = (e) => {
    e.preventDefault()
      navigate("/contact")
  }
  return (
    <section className={classes.root}>
      <Container>
        <div className={classes.card}>
          <Typography variant="h1" paragraph>
            Are you an agency owner, Fam organizer, or other Travel Advisor group?
          </Typography>
          <Typography variant="h2" color="primary" className={classes.text}>
            Talk to us today about a customized plan.
          </Typography>
          <div className={classes.buttonWrapper}>
            <Button variant="contained" color="primary" size="large" className={classes.button} onClick={handleClickContact}>
                Contact Sales
            </Button>
          </div>
        </div>
        <div className={classes.faqTextContainer}>
          <Typography variant="h1" paragraph>
            Frequently Asked Question
          </Typography>
          {/* <Typography variant="h2" className={classes.text}>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
          </Typography> */}
        </div>
        <div className={classes.faqContainer}>
          <Accordion>
            <AccordionSummary expandIcon={<PlusIcon />}>How do I signup?</AccordionSummary>
            <AccordionDetails>
              <Typography variant="h2">
                Easy! Simply download the FamGuru app from the App Store and sign up using your preferred email address.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<PlusIcon />}>Is a credit card required?</AccordionSummary>
            <AccordionDetails>
              <Typography variant="h2">
                No. You can sign up for FamGuru Free and add one Fam Trip up to 14 days in duration. We want you to try
                it first before subscribing! When you’re ready to add more Fam Trips, you can upgrade to FamGuru
                Premium.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<PlusIcon />}>Is there a web version of FamGuru?</AccordionSummary>
            <AccordionDetails>
              <Typography variant="h2">
                Currently no. We believe a Mobile-first strategy is the best approach for busy Travel Advisors,
                especially given how convenient it is to simply record your Fam Insights on your phone or iPad.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<PlusIcon />}>How can I share a Fam Trip?</AccordionSummary>
            <AccordionDetails>
              <Typography variant="h2">
                You can do so currently in two ways. <br />
                1) By sharing your photos direct to Social Media from FamGuru. <br />
                2) by sharing the downloadable trip report with your colleagues or clients.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<PlusIcon />}>Can FamGuru help me sell more travel?</AccordionSummary>
            <AccordionDetails>
              <Typography variant="h2">
                It absolutely can! FamGuru helps you document the details of your Fam Trip as you experience it – you
                become a more effective seller of travel with your Fam knowledge at your fingertips. The app also does
                the heavy lifting – just fill in the blanks – so you save time on trip reports and photo organization,
                time you can spend with your clients instead to sell more travel. And with all those photos organized,
                you can post your best shots right to your social media, during or after travel, so prospective clients
                know where you’ve been and contact you about travel plans. It’s all about saving time, being more
                effective and sharing what you know so you can better focus on selling travel.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<PlusIcon />}>Do you support Android?</AccordionSummary>
            <AccordionDetails>
              <Typography variant="h2">
                Our first versions are made for iOS, the iPhone and iPad operating system. As our user base grows we
                will be adding a version for Android as well.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </Container>
    </section>
  );
}
