import React from 'react';

import SEO from '../components/seo';
import SectionA from '../features/pricing/sectionA';
import SectionB from '../features/pricing/sectionB';

export default function HomePage() {
  return (
    <>
      <SEO title="Pricing" />
      <SectionA />
      <SectionB />
    </>
  );
}
