import React from 'react';
import clsx from 'clsx';
import { makeStyles, Typography, Container, Box, Grid, Hidden, ButtonBase } from '@material-ui/core';

import PriceCard, { Feature } from '../../components/priceCard';

const planPrices = {
  'monthly' : 9.99,
  'yearly': 6.25
}
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(8, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 0),
    },
  },

  pricePeriod: {
    padding: theme.spacing(1),
    backgroundColor: '#F7F7F7',
    borderRadius: 50,
    height: 64,
    display: 'flex',
    alignItems: 'center',
  },

  buttonActive: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },

  button: {
    height: 50,
    borderRadius: 50,
    width: 115,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 242,
    margin: 'auto',
    padding: theme.spacing('100px', 0, 8),
  },

  cardContainer: {
    padding: theme.spacing(5, 0),
  },
}));

export default function SectionA() {
  const classes = useStyles();

  const [type, setType] = React.useState('monthly');
  const handleClick = event => {
    setType(event.currentTarget.name);
  };

  return (
    <section className={classes.root}>
      <Container>
        <Typography variant="subtitle1" align="center" gutterBottom>
          PRICING
        </Typography>
        <Typography variant="h1" paragraph align="center">
          Choose from two options to get started with FamGuru
        </Typography>
        <Typography variant="h2" paragraph align="center">
          Sign up now and get a 30-day free trial of FamGuru Premium. No credit card required.
        </Typography>
        <div className={classes.container}>
          <Box width="100%">
            <Typography
              color="primary"
              align="right"
              gutterBottom
              style={{ marginRight: 32, fontWeight: 900, fontSize: 12 }}
            >
              Save 20%
            </Typography>
          </Box>
          <div className={classes.pricePeriod}>
            <ButtonBase
              name="monthly"
              className={clsx(classes.button, type === 'monthly' && classes.buttonActive)}
              onClick={handleClick}
            >
              <Typography variant="button">Monthly</Typography>
            </ButtonBase>
            <ButtonBase
              name="yearly"
              className={clsx(classes.button, type === 'yearly' && classes.buttonActive)}
              onClick={handleClick}
            >
              <Typography variant="button">Yearly</Typography>
            </ButtonBase>
          </div>
        </div>
        <div className={classes.cardContainer}>
          <Grid container justify="center">
            <Grid item xs={12} md={6}>
              <PriceCard title="FamGuru Free">
                <Feature>
                  <Typography variant="h2">1 Fam trip up to 14 days in duration</Typography>
                </Feature>
                <Feature>
                  <Typography variant="h2">All app features</Typography>
                </Feature>
                <Feature>
                  <Typography variant="h2">Share your Fam on social media, access</Typography>
                  <Typography variant="h2">to your FamInfo and report download</Typography>
                </Feature>
              </PriceCard>
            </Grid>
            <Hidden mdUp>
              <Grid item xs={12}>
                <Box paddingY={5} />
              </Grid>
            </Hidden>
            <Grid item xs={12} md="auto">
              <PriceCard title="FamGuru Premium" premium>
                <Feature>
                  <Typography variant="h2">Unlimited trips - record as many Fams</Typography>
                  <Typography variant="h2">as you like; Unlimited trip duration</Typography>
                </Feature>
                <Feature>
                  <Typography variant="h2">All app features</Typography>
                </Feature>
                <Feature>
                  <Typography variant="h2">Add your own branding and logo</Typography>
                </Feature>
                <Feature>
                  <Typography variant="h2">{`Per Month $${planPrices[type]}`}</Typography>
                </Feature>
              </PriceCard>
            </Grid>
          </Grid>
        </div>
      </Container>
    </section>
  );
}
