/* eslint-disable react/prop-types */
import React from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';

import CheckIcon from '../assets/icons/check.inline.svg';

const useStyles = makeStyles(theme => ({
  card: ({ premium }) => ({
    borderRadius: 11,
    minHeight: 689,
    backgroundColor: premium ? 'rgba(220, 69, 139, 0.1)' : '#F7F7F7',
    maxWidth: 568,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: premium && '2px solid #DC458B',
    margin: 'auto',
  }),

  cardHeader: ({ premium }) => ({
    padding: theme.spacing(5, 3),
    borderBottom: '2px solid',
    textAlign: 'center',
    borderBottomColor: premium ? theme.palette.primary.main : '#E2E2E2',
  }),

  cardBody: {
    padding: theme.spacing(6),
    flexGrow: 1,
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    '& > *:first-child': {
      flexGrow: 1,
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6, 3),
    },
  },

  button: {

  },

  feature: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(5),
    '& > *:first-child': {
      marginRight: 30,
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      '& > *:first-child': {
        marginRight: 0,
        marginBottom: 20,
      },
    },
  },
}));

export function Feature({ children }) {
  const classes = useStyles();

  return (
    <div className={classes.feature}>
      <CheckIcon />
      <div>{children}</div>
    </div>
  );
}

export default function PriceCard({ title, premium, children }) {
  const classes = useStyles({ premium });

  return (
    <div className={classes.card}>
      <div className={classes.cardHeader}>
        <Typography variant="h1">{title}</Typography>
      </div>
      <div className={classes.cardBody}>
        <div className={classes.featuresContainer}>{children}</div>
      </div>
    </div>
  );
}
